import React, { useContext, useEffect } from 'react';

import { Beginning, ApplicationConsumer } from '@local';

const Index = () => {

  const {
    setCurrentStepSetup,
    setStep,
    setPageNumber,
    setPagesFilledIn,
    application,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useContext(ApplicationConsumer);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.clear();
      // List of application state management that need to be initialized
      setStep(0);
      setPageNumber(0);
      setPagesFilledIn(0);
      setCurrentStepSetup(application.pages[0].steps[0]);
    }
  })

  return (
    <div className="row">
      <div className="col-sm-5 col-md-5 col-lg-7 offset-md-1 offset-lg-4">
        <Beginning />
      </div>
    </div>
  );
};

export default Index;
